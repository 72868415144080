<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button  v-if="userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111'" variant="primary" @click="socialmediareportAdd">{{cvbtnAddNew}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="mb-3">
              <b-col class="col-12 col-sm-9 col-md-4 col-lg-3 mb-2">
                <b-form-input
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"
                ></b-form-input>
              </b-col>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-2">
                <select v-if="userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111'" v-model="whereFilter.moduleType" class="form-control" @change="setFilters" required>
                    <option value="" disabled>Choose Org Type</option>
                    <option value="All">All</option>
                    <option v-for="(orgType, index) of cvUserTypesOptions" :key="(index+1)" :value="orgType.key">
                      {{orgType.value}}
                    </option>
                  </select>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-2 mb-2">
                <select v-if="userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111'" class="form-control" v-model="whereFilter.platform" @change="setFilters">
                  <option value="" disabled>Choose Platform</option>
                  <option value="All">All</option>
                  <option v-for="(Social_platform, index) of SociaMediaPlatforms" :key="(index+1)" :value="index">{{ Social_platform }}</option>
                </select>
              </div>
              <b-col class="col-12 col-sm-12 col-md-12 col-lg-3 pr-0 mb-3">
                <div class="dateRange">
                  <date-range-picker ref="picker" class="w-100"  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="getSocialMediaReportDateWise()" ></date-range-picker>
                </div>
              </b-col>
              <b-col class="col-12 col-sm-3 col-md-2 col-lg-1 mb-2">
                <!-- Backend csv download -->
                <b-btn  v-if="userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111'" variant="primary" class="Download_button" @click="backendCsvDownload"> CSV</b-btn>
                <!-- Backend csv download -->
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" v-if="socialmediareportObjList && socialmediareportObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="socialmediareportObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                <template v-slot:cell(topic)="data">
                  <span>
                    <p class="m-0" v-html="data.item.topic"></p>
                    <small v-if="userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111'" v-html="data.item.creative_id" style="word-wrap: break-word" class="mb-0"></small>
                    <i v-if="userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111'" class="fa-solid fa-copy pointer pl-2" @click="doCopy(data.item.creative_id)"></i>
                  </span>
                  <br>
                  <b-button variant="iq-bg-success mr-1 mb-1" @click="showSocialMedia(data.item.url)" size="sm" v-if="!data.item.editable && data.item.url && (userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111')" >
                    <i class="fa fa-link m-0 actionIcon font-size-20"></i>
                  </b-button>
                  <b-button variant="iq-bg-success mr-1 mb-1" size="sm" @click="socialmediareportEdit(data.item)" v-if="!data.item.editable && (userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111')" >
                    <i class="ri-ball-pen-fill m-0 actionIcon font-size-20"></i>
                  </b-button>
                  <b-button variant="iq-bg-danger" @click="showSocialMediaReportDeleteDialog(data.item)" v-if="!data.item.editable && (userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111')" size="sm">
                    <i class="ri-delete-bin-line m-0 actionIcon font-size-20"></i>
                  </b-button>
                </template>
                <template v-slot:cell(module_name)="data">
                  <p class="m-0" v-html="data.item.module_name"></p>
                  <small v-if="userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111'" v-html="data.item.module_id" style="word-wrap: break-word" class="mb-0"></small>
                  <i v-if="userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111'" class="fa-solid fa-copy pointer pl-2" @click="doCopy(data.item.module_id)"></i><br>
                  <b-badge v-if="data.item.type === 'UNIV'" class="pointer" variant="success mr-1 mb-1" size="sm">
                      University&nbsp;
                    </b-badge>
                    <b-badge v-if="data.item.type === 'SCH'" class="pointer" variant="warning mr-1 mb-1" size="sm">
                      School
                    </b-badge>
                    <b-badge v-if="data.item.type === 'ORG'" class="pointer" variant="primary mr-1 mb-1" size="sm">
                      organisation
                    </b-badge>
                </template>
                <template v-slot:cell(post_thumbnail)="data">
                  <div class="text-wrap">
                    <b-button variant="mb-1" title="View Submited Application" size="sm">
                      <img src="../../../assets_gide/img/logo/gide_logo.png" style="width:24px"/>
                    </b-button>
                  </div>
                </template>
                <template v-slot:cell(smr_img)="data">
                  <img :src="data.item.smr_img" style="height:100px" class="fourLineOnly"/>
                </template>
                <template v-slot:cell(created_on)="data">
                  {{getFormattedDateTime(data.item.created_on)}}
                </template>
                </b-table>
              </b-col>
              <b-col md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelSocialMediaReportAdd"
      scrollable
      :title="cvAddModalHeader"
      size="xl"
    >
      <SocialMediaReportAdd :propOpenedInModal="true" @emitCloseSocialMediaReportAddModal="closeSocialMediaReportAddModal" />
      <template #modal-footer="">
        <b-button size="sm" @click="closeSocialMediaReportAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelSocialMediaReportEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl"
    >
      <SocialMediaReportEdit :propOpenedInModal="true" :propSocialMediaReportObj="socialmediareportEditObj" @emitCloseSocialMediaReportEditModal="closeSocialMediaReportEditModal" />
      <template #modal-footer="">
        <b-button size="sm" @click="closeSocialMediaReportEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelSocialMediaReportDelete"
      scrollable
      title="Delete"
      ok-title="Delete"
      cancel-title="Cancel"
      size="xl"
      >
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelSocialMediaReportDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="socialmediareportDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import SociaMediaPlatforms from "../../../FackApi/json/SocialMediaPlatform.json"
import { SocialMediaReports } from "../../../FackApi/api/socialmediareport.js"
import SocialMediaReportAdd from "./SocialMediaReportAdd"
import SocialMediaReportEdit from "./SocialMediaReportEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"
import { socialvue } from "../../../config/pluginInit.js"

export default {
  name: "SocialMediaReportList",
  components: {
    SocialMediaReportAdd,
    SocialMediaReportEdit
  },
  data () {
    return {
      cvCardTitle: "Social Media Report",
      cvbtnAddNew: "Add",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Social Media Report",
      cvAddModalHeader: "Add Social Media Report",
      cvbtnModalCancel: "Cancel",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Social Media Report List Response",
      showModelSocialMediaReportAdd: false,
      showModelSocialMediaReportEdit: false,
      showModelSocialMediaReportDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Topic", key: "topic", class: "text-left align-text-top w-200px", sortable: true },
        { label: "Organization name", key: "module_name", class: "text-left align-text-top w-300px", sortable: true },
        { label: "Platform", key: "platform", class: "text-left align-text-top", sortable: true },
        { label: "Deliverable type", key: "deliverable_type", class: "text-left align-text-top w-100px", sortable: true },
        { label: "Reach", key: "reach", class: "text-left align-text-top w-100px", sortable: true },
        { label: "Impression", key: "impression", class: "text-left align-text-top w-100px", sortable: true },
        { label: "Likes", key: "likes", class: "text-left align-text-top w-100px", sortable: true },
        { label: "Saves", key: "saves", class: "text-left align-text-top w-100px", sortable: true },
        { label: "Shares", key: "shares", class: "text-left align-text-top w-100px", sortable: true },
        { label: "image", key: "smr_img", class: "text-left w-100px", sortable: true },
        { label: "Comments", key: "comments", class: "text-left align-text-top w-100px", sortable: true }
      ],
      socialmediareportObjList: null,
      socialmediareportEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null,
      univFiltersList: [],
      socialMediaFiltersList: [],
      SociaMediaPlatforms: SociaMediaPlatforms,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 365)), // From Last Year
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        },
        platform: "All",
        moduleType: "All"
      },
      cvUserTypesOptions: [
        { key: "UNIV", value: "University" },
        { key: "ORG", value: "Organization" },
        { key: "SCH", value: "School" }
      ]
    }
  },
  computed: {
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    },
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async mounted () {
    socialvue.index()
    this.cvOrgId = this.$route.query.org_id
    await this.getDateFilter()
    this.socialmediareportList()
  },
  methods: {
    /**
     * Calling Function as per whereFilter
    */
    async getSocialMediaReportDateWise () {
      this.socialmediareportList()
      await this.setDateFilter()
    },
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
    */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter = storeDate
      }
    },
    /**
     * Setting Date Range in the LS
    */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * socialmediareportList
     */
    async socialmediareportList (downloadCsv = false) {
      try {
        if (this.cvOrgId) {
          this.whereFilter.module_id = this.cvOrgId
        }
        this.whereFilter.downloadCsv = downloadCsv

        let socialmediareportListResp = await SocialMediaReports.socialmediareportList(this, this.whereFilter)
        if (socialmediareportListResp.resp_status) {
          if (downloadCsv) {
            window.open(socialmediareportListResp.resp_csv_file_url)
            return
          }
          this.socialmediareportObjList = socialmediareportListResp.resp_data.data
          this.totalRows = socialmediareportListResp.resp_data.count
        }
        else {
          this.toastMsg = socialmediareportListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at socialmediareportList() and Exception:", err.message)
      }
    },
    /**
     * socialmediareportAdd
     */
    socialmediareportAdd () {
      this.showModelSocialMediaReportAdd = true
    },
    /**
     * socialmediareportEdit
     */
    socialmediareportEdit (item) {
      this.socialmediareportEditObj = item
      this.showModelSocialMediaReportEdit = true
    },
    /**
     * showSocialMediaReportDeleteDialog
     */
    showSocialMediaReportDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelSocialMediaReportDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showSocialMediaReportDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * socialmediareportDelete
     */
    async socialmediareportDelete () {
      try {
        let socialmediareportDelResp = await SocialMediaReports.socialmediareportDelete(this, this.delObj.module_id)
        await ApiResponse.responseMessageDisplay(this, socialmediareportDelResp)

        if (socialmediareportDelResp && !socialmediareportDelResp) {
          this.showModelSocialMediaReportDelete = false
          return false
        }

        let index = this.socialmediareportObjList.indexOf(this.delObj)
        this.socialmediareportObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelSocialMediaReportDelete = false
      }
      catch (err) {
        console.error("Exception occurred at socialmediareportDelete() and Exception:", err.message)
      }
    },
    /**
     * closeSocialMediaReportAddModal
     */
    closeSocialMediaReportAddModal () {
      try {
        this.showModelSocialMediaReportAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closesocialmediareportAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeSocialMediaReportEditModal
     */
    closeSocialMediaReportEditModal () {
      try {
        this.socialmediareportList()
        this.showModelSocialMediaReportEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeSocialMediaReportEditModal() and Exception:", err.message)
      }
    },
    /**
     *  Do Copy
     */
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")

      document.body.appendChild(dummy)
      // Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
      console.log("Msg Copy")

      // this.snackbar = true;
      // this.err_msg = 'Copied';
    },
    /**
     * backendCsvDownload
    */
    async backendCsvDownload () {
      this.socialmediareportList(true)
    },
    /**
     * setFilters
    */
    async setFilters () {
      await this.socialmediareportList()
    },
    /**
     * showSocialMedia
    */
    showSocialMedia (url) {
      window.open(url, "_blank")
    }
  }
}
</script>
