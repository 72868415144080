<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <ValidationObserver ref="socialMediaReport">
            <form action="#" v-if="vmSocialMediaReportFormData && Object.keys(vmSocialMediaReportFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationmodule_type">
                    <b>{{ propSocialMediaReportObj.module_name }}</b></label>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationmodule_type">{{ cvModuleTypeLabel }}</label>
                  <select v-model="ModuleType" class="form-control" @change="setPlatformFilter" required>
                    <option value="" disabled>Choose Org Type</option>
                    <option v-for="(orgType, index) of cvUserTypesOptions" :key="(index+1)" :value="orgType.key">
                      {{orgType.value}}
                    </option>
                  </select>
                </div>
                <div class="col-md-12 mb-3" v-if="ModuleType && ModuleType.length > 0">
                  <label for="validationmodule_type">
                    {{ cvModuleNameLabel }}
                    <span v-if="ModuleType == 'ORG'">Organisation: </span>
                    <span v-if="ModuleType == 'SCH'">Shool: </span>
                    <span v-if="ModuleType == 'UNIV'">University: </span>
                  </label>
                  <multiselect for="validationmodule_name"
                    v-model="module_data"
                    :options="univFiltersList['org_name , org_id']"
                    placeholder= "Select One"
                    track-by="org_id"
                    label="org_name"
                    class="mt-2"
                    @search-change="OrganisationsList($event)"
                    >
                    <span slot="noResult">No school found</span>
                  </multiselect>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationtopic">{{ cvTopicLabel }}</label><label class="primary-color font-size-16">*</label>
                  <ValidationProvider
                    :name="cvTopicLabel"
                    v-slot="{ errors }">
                    <input v-model="vmSocialMediaReportFormData.topic" type="text" class="form-control" required />
                    <span class="text-danger"> {{ errors[0]}} </span>
                  </ValidationProvider>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationcreative_id">{{ cvCreativeIdLabel }}</label><label class="primary-color font-size-16">*</label>
                  <ValidationProvider
                    :name="cvCreativeIdLabel"
                    v-slot="{ errors }">
                  <input v-model="vmSocialMediaReportFormData.creative_id" type="text" class="form-control" required />
                  <span class="text-danger"> {{ errors[0]}} </span>
                  </ValidationProvider>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationplatform">{{ cvPlatformLabel }}</label><label class="primary-color font-size-16">*</label>
                  <ValidationProvider
                    :name="cvPlatformLabel"
                    rules="required"
                    v-slot="{ errors }">
                    <select class="form-control" v-model="vmSocialMediaReportFormData.platform">
                      <option v-for="(Social_platform, index) of SociaMediaPlatforms" :key="(index+1)" :value="index">{{ Social_platform }}</option>
                    </select>
                    <span class="text-danger"> {{ errors[0]}} </span>
                  </ValidationProvider>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationdeliverable_type">{{ cvDeliverableTypeLabel }}</label><label class="primary-color font-size-16">*</label>
                  <ValidationProvider
                    :name="cvDeliverableTypeLabel"
                    rules="required"
                    v-slot="{ errors }">
                    <select class="form-control" v-model="vmSocialMediaReportFormData.deliverable_type">
                      <option v-for="(DeliverableType, index) of DeliverableType" :key="index+'hosting_platform'" :value="index" >
                        {{ DeliverableType }}
                      </option>
                    </select>
                    <span class="text-danger"> {{ errors[0]}} </span>
                  </ValidationProvider>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationUrl">{{ cvUrlLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.url" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationreach">{{ cvReachLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.reach" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationimpression">
                    {{ cvImpressionLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.impression" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationlikes">
                    {{ cvLikesLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.likes" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationsaves">
                    {{ cvSavesLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.saves" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationshares">
                    {{ cvSharesLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.shares" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationcomments">
                    {{ cvCommentsLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.comments" type="text" class="form-control" required />
                </div>
                <div class="form-group row align-items-center">
                  <div class="col-md-12 mb-4">
                    <div class="profile-img-edit border" style="height: 100%; width: 100%; max-width:300px">
                      <img v-if="vmCoverPic" :src="vmCoverPic" alt="cover-pic " style="height: 100%; width: 100%; object-fit: cover; overflow: hidden;" />
                      <i v-else @click="uploadClick()" class="fas fa-image d-flex justify-content-center align-items-center my-5" style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
                      <h5 class="p-image" @click="uploadClick()">
                        <i class="ri-pencil-line upload-button" style="cursor: pointer;"></i>
                        <input class="file-upload" type="file" accept="image/png, image/jpeg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0; pointer-events: none;" @change="openImageDialog($event, 'Cover Pic')" />
                      </h5>
                    </div>
                  </div>
                  <button type="button" class="p-2 rounded btn btn-primary border-0 mb-2 mr-2"  :disabled="cvCoverPicError || cvCoverPicLoading" @click="imageEdit('cover_pic')">
                    <b-spinner small class="ml-4 mr-4" v-if="cvCoverPicLoading" h5="Spinning"></b-spinner>
                    <span v-else>Save Profile Pic</span>
                  </button>

                  <button v-if="vmCoverPic" type="button" class="p-2 rounded btn btn-primary border-0 mb-2" :disabled="cvCoverPicError || cvCoverPicLoading"  @click="imageRemove('cover_pic')">
                    <b-spinner small class="ml-4 mr-4" v-if="cvDelCoverPicLoading" h5="Spinning">
                    </b-spinner>
                    <span v-else>Remove Profile Pic</span>
                  </button>
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                  <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="socialmediareportEdit()">{{ cvSubmitBtn }}</button>
              </div>
            </form>
          </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <CropImage :prop-crop-image-dialog-open="propCropImageDialogOpen" :propCropModalSize="propCropDialogSize" :prop-stencil-size="propStencilSize" :propSrc="fileSrc" @emitCroppedImg="getCroppedImg" @emitCroppedImgFile="emitCroppedImgFile" />

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css">
  .multiselect__select{
    top:5px;
  }
</style>
<script>
import SociaMediaPlatforms from "../../../FackApi/json/SocialMediaPlatform.json"
import { SocialMediaReports } from "../../../FackApi/api/socialmediareport"
import DeliverableType from "../../../FackApi/json/DeliverableType.json"
import ApiResponse from "../../../Utils/apiResponse.js"
import { Images } from "../../../FackApi/api/image"
import CropImage from "../../../components/cropImage.vue"
import userPermission from "../../../Utils/user_permission.js"
import { Organisations } from "../../../FackApi/api/organisation.js"
import Multiselect from "vue-multiselect"
import { ValidationProvider } from "vee-validate"

export default {
  name: "SocialMediaReportEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propSocialMediaReportObj: {
      type: Object,
      default: function () {
        return {
          "creative_id": "",
          "module_name": "",
          "platform": "",
          "deliverable_type": "",
          "topic": "",
          "reach": "",
          "impression": "",
          "likes": "",
          "saves": "",
          "shares": "",
          "comments": ""
        }
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  components: {
    CropImage,
    Multiselect,
    ValidationProvider
  },
  data () {
    return {
      apiName: "socialmediareport_edit",
      cvCardTitle: "Edit socialmediareport",
      cvCardSubHeader: "Edit socialmediareport",
      cvSubmitBtn: "Edit",
      cvTopicLabel: "Topic",
      cvUrlLabel: "Add URL",
      cvModuleNameLabel: "Select Your",
      cvPlatformLabel: "Platform",
      cvModuleTypeLabel: "Organization Type",
      cvDeliverableTypeLabel: "Deliverable type",
      cvCreativeIdLabel: "Creative id",
      cvReachLabel: "Reach",
      cvImpressionLabel: "Impression",
      cvLikesLabel: "Likes",
      cvSavesLabel: "Saves",
      cvSharesLabel: "Shares",
      cvCommentsLabel: "Comments",
      showToast: false,
      cvLoadingStatus: false,
      DeliverableType: DeliverableType,
      SociaMediaPlatforms: SociaMediaPlatforms,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "SocialMediaReport",
      vmSocialMediaReportFormData: {},
      propCropImageDialogOpen: false,
      vmCoverPic: "",
      cvCoverPicError: false,
      cvCoverPicLoading: false,
      cvDelCoverPicLoading: false,
      propStencilSize: null,
      univFiltersList: { "org_name , org_id": [] },
      module_data: {},
      ModuleType: null,
      propCropDialogSize: "md",
      cvImageSizeErrorMsg: "Image size must be less than 300 kb.",
      fileSrc: "",
      cvUserTypesOptions: [
        { key: "UNIV", value: "University" },
        { key: "ORG", value: "Organization" },
        { key: "SCH", value: "School" }
      ]
    }
  },
  mounted () {
    this.vmCoverPic = this.propSocialMediaReportObj.smr_img
    if (!userPermission(this, this.userData, this.apiName)) {
      window.location.reload()
      return
    }
    this.socialmediareportView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmSocialMediaReportFormData) {
          if (!this.vmSocialMediaReportFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * socialmediareportView
     */
    async socialmediareportView () {
      try {
        if (this.propOpenedInModal) {
          this.vmSocialMediaReportFormData = this.propSocialMediaReportObj
        }
        else {
          let moduleId = this.$route.params.module_id
          let socialmediareportViewResp = await SocialMediaReports.socialmediareportView(this, moduleId)

          if (socialmediareportViewResp && socialmediareportViewResp.resp_status) {
            this.vmSocialMediaReportFormData = socialmediareportViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at socialmediareportView() and Exception:", err.message)
      }
    },
    /**
     * socialmediareportEdit
     */
    async socialmediareportEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        let valid = await this.$refs.socialMediaReport.validate()
        if (!valid) {
          return
        }
        this.cvLoadingStatus = true
        this.vmSocialMediaReportFormData.module_name = this.module_data.org_name
        this.vmSocialMediaReportFormData.module_id = this.module_data.org_id
        let socialmediareportAddResp = await SocialMediaReports.socialmediareportEdit(this, this.vmSocialMediaReportFormData)
        await ApiResponse.responseMessageDisplay(this, socialmediareportAddResp)

        if (socialmediareportAddResp && !socialmediareportAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseSocialMediaReportEditModal", this.vmSocialMediaReportFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at socialmediareportEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /*
     * univFilterList
     */
    async univFilterList (filterName = null) {
      if (!filterName) {
        return
      }
      let selectedType = this.ModuleType

      try {
        let univFilterListResp = await Organisations.organisationFilterList(this, selectedType, filterName)
        if (univFilterListResp) {
          this.univFiltersList[filterName] = univFilterListResp.resp_data
        }
      }
      catch (err) {
        console.error("Exception occurred at univFilterList() and Exception:", err.message)
      }
    },
    /*
     * setPlatformFilter
     */
    setPlatformFilter () {
      this.univFilterList("org_name , org_id")
    },
    /**
     * imageEdit
     */
    async imageEdit (imageType) {
      if (imageType === "cover_pic" && this.vmPBACoverPicImage.image.length === 0) {
        return false
      }
      try {
        let image
        this.cvCoverPicLoading = true
        image = this.vmPBACoverPicImage
        const imageUpdateResp = await Images.imageProfileUpdate(this, image)
        await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
        if (imageUpdateResp && !imageUpdateResp.resp_status) {
          return false
        }
        let imageResp = imageUpdateResp.resp_data
        if (imageResp && imageResp.length > 0) {
          this.coverPicObj = imageResp[0]
        }
      }
      catch (err) {
        console.error("Exception occurred at imagEdit() and Exception:", err.message)
      }
      finally {
        this.cvCoverPicLoading = false
      }
    },
    /**
     * openImageDialog
     *
     */
    openImageDialog (e, imageType) {
      this.updateImageType = imageType
      if (e.target.files.length > 0) {
        this.fileSrc = e.target.files[0]
      }
      let input = e.target
      if (input.files.length > 0 && input.files[0]) {
        let image = input.files[0]
        let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]
        let maxSize = 300 * 1024 // 300 KB in bytes
        if (!allowedExtension.includes(image.type)) {
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          return false
        }
        else if (image.size > maxSize) {
          // validate image size
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.toastVariant = "danger"
            this.showToast = true
          }
          return false
        }
        this.propStencilSize = {
          "width": 540,
          "height": 283
        }
        this.propCropDialogSize = "md"
        this.propCropImageDialogOpen = Math.random()
      }
    },
    /**
     * onChangeProfileImage
     */
    onChangeProfileImage (e, imageType) {
      try {
        const reader = new FileReader()
        reader.onload = async (e) => {
          if (imageType === "Profile Pic") {
            this.vmProfilePic = await reader.result
          }
          else {
            this.vmCoverPic = await reader.result
          }
        }
        // reader.readAsDataURL(image)
      }
      catch (err) {
        console.error("Exception occurred at onChangeProfilePic() and Exception:", err.message)
      }
    },
    /**
     * getCroppedImg
     */
    getCroppedImg (img) {
      if (this.updateImageType === "Profile Pic") {
        this.vmProfilePic = img
      }
      else {
        this.vmCoverPic = img
      }
    },
    /**
     * emitCroppedImgFile
     */
    emitCroppedImgFile (imgObj) {
      let img = imgObj.imgFile
      let sociaImg = imgObj.orgImage
      let imageType = this.updateImageType
      this.onChangeProfileImage(img, imageType)
      this.vmPBACoverPicImage = Object.assign({}, this.initImageData())
      this.vmPBACoverPicImage.image.push(
        {
          "img": img,
          "name": sociaImg.name
        }
      )
      this.vmPBACoverPicImage.image_type[sociaImg.name] = 3
    },
    /**
     * initImageData
     */
    initImageData () {
      return {
        module_id: this.propSocialMediaReportObj.smr_id,
        module_name: "SMR",
        image: [],
        image_type: {}
      }
    },
    /**
     * uploadClick
     */
    uploadClick () {
      let id = document.getElementById("fileInput1")
      if (id) {
        id.click()
      }
    },
    /**
     * imageRemove
     */
    async imageRemove (imgType) {
      if (imgType === "profile_pic") {
        this.cvDelProfilePicLoading = true
      }
      else {
        this.cvDelCoverPicLoading = true
      }
      let img = imgType === "profile_pic" ? 2 : 3
      const imageUpdateResp = await Images.imageDelete(this, this.propSocialMediaReportObj.smr_id, img)
      await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
      if (imageUpdateResp && !imageUpdateResp.resp_status) {
        return false
      }
      if (imgType === "profile_pic") {
        this.vmProfilePic = null
        this.cvDelProfilePicLoading = false
      }
      else {
        this.vmCoverPic = null
        this.cvDelCoverPicLoading = false
      }
    }
  }
}
</script>
